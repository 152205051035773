import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://www.tsdevcut.co.za/wp-content/themes/tsdev/images/mecartioon-18-normal-llight.png" className="App-logo" alt="logo" />
        <h1>
          Edit <code>src/App.js</code> and save to reload.
        </h1>
        
      </header>
    </div>
  );
}

export default App;
